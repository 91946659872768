import React, { useEffect, useState } from "react"
import Layout from "@/components/layout"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import LogoApp from "../assets/images/logosvg.svg"
import GamesImg from "../assets/images/app-games.svg"
const AboutPage = () => {

  const [date, setDate] = useState()
  useEffect(() => {
    const date = new Date().getFullYear()
    setDate(date)
  }, [])

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="About Us">
          <div className="container">
            <img className="app-page-logo" src={LogoApp}></img>
          </div>
          <section className="commonSection">
            <div className="single_blog">
              <div className="blog_headings">
                <h2 style={{ textAlign: "center" }}>
                  Cloud Mobile Scoring Platform
                </h2>
                <img src={GamesImg} className="app-games"></img>
              </div>
              <div className="blog_details">
                <p>
                  Easy to manage, easy to enter cloud-based score management
                  solution for your cricket, football and other clubs.
                  <br />
                  You can cover your club cricket scores LIVE now and view them
                  realtime in this scoreblox app, also easy to friends and
                  family.
                </p>
                <p>
                  And soon to come other popular sports as well! Your events and
                  matches - your way!
                </p>
                {/* <strong>
                  <button
                    className="sign-up-btn-app"
                    type="submit"
                    id="con_submit"
                    onClick={e => {
                      window.open(
                        process.env.GATSBY_WEB_URL + "/pricing#features"
                      )
                    }}
                  >
                    <span>View Details</span>
                  </button>
                </strong> */}
              </div>
            </div>
          </section>
          <footer className="footer_1 footer-app" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-sm-12 text-center">
                  <div className="copyright">
                    © copyright {date} by scoreblox.com{" "}
                    <br /> a Kawntr Limited venture - All rights reserved
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}
export default AboutPage
